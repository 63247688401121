import React, { useEffect } from 'react'
import Head from 'next/head'
import { IPage } from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import getDefaultCmsTransport from '../src/services/api/cmsInterceptor'
import { CMS_API_CALLS } from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import { ITranslationPageApiParams } from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS/page'
import CACHE_CONTSTANT from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/cache/constants'
import Error404 from '../src/components/Tenant/Layout/Error404/Error404'
import renderPropsMethods from '../src/components/Tenant/Widgets/index'
import getLocationData from '../src/components/Utils/getGeolocationFromCookie'
import Page from '../src/components/Tenant/Layout/Page/Page'
import { getClientIPAddress, parseRequestUrlAndPath } from '../src/utils/pages/pagesUtil'
import { AppContextType } from 'next/dist/shared/lib/utils'

interface IRegularPageProps {
  data: IPage
  error: string
  canonicalUrl: string
  clientIPAddress: string
}

const RegularPage = (props: IRegularPageProps) => {
  const { data, error, canonicalUrl, clientIPAddress } = props
  if (error || !data) {
    return <Error404 />
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const locationData = getLocationData()
  const page: IPage = data
  if (page) {
    return (
      <>
        <Head>
          <title key="title">{page.title}</title>
          <meta name="description" content={page.description} />
          <meta name="keywords" content={page.keywords} />
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} key="canonical" />}
          {page.robots && page.robots !== '' && <meta name="robots" content={page.robots} />}
        </Head>
        {locationData && (
          <Page
            Head={Head}
            transport={getDefaultCmsTransport(true)}
            data={page.snapshot.lookup}
            version={page.snapshot.version || '1'}
            locationData={locationData}
            renderHooks={{
              ...renderPropsMethods,
            }}
          />
        )}
      </>
    )
  }
  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>404</div>
    </>
  )
}

RegularPage.getInitialProps = async (props: AppContextType) => {
  try {
    const { query, req } = props.ctx
    const params: ITranslationPageApiParams = {
      route: query.id as string,
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.CONTENT.PAGE}-${query.id}`,
    }
    if (query.lang) {
      params.lang = query.lang as string
      params.cacheKey = `${CACHE_CONTSTANT.FRONTEND.CONTENT.PAGE}-${query.id}-${query.lang}`
    }
    const pageResponse = await CMS_API_CALLS.PAGE.getTranslationData(getDefaultCmsTransport(true), params)
    // for rel canonical tags
    let pageCanonicalUrl = pageResponse.data?.metaCanonicalUrl
    if (!pageCanonicalUrl) {
      const { canonicalUrl } = parseRequestUrlAndPath(props.ctx, false, false)
      pageCanonicalUrl = canonicalUrl
    }
    // If no page returned, set statusCode to 404 Not found, to avoid returning 200 Ok as default
    if (pageResponse?.error && !pageResponse?.data && props?.ctx?.res) {
      props.ctx.res.statusCode = 404
    }
    return {
      error: pageResponse.error,
      data: pageResponse.data,
      canonicalUrl: pageCanonicalUrl,
      clientIPAddress: getClientIPAddress(req),
    }
  } catch (e) {
    return { error: 'page not found', data: null }
  }
}

export default RegularPage
