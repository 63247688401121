import React from 'react'
import LocationContext, { ILocationContextProviderProps } from '../../Context/LocationContext'

import OmniPage from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage'
import { ILocationData } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import getLocationData from '../../../Utils/getGeolocationFromCookie'
import { IMAGE_PROXY_URL } from '../../../../settings/variables'

const Page = (props: any) => {
  const [locationData, setLocationData] = React.useState<ILocationData | null>()
  const { myClosestStore } = React.useContext(LocationContext.Context) as ILocationContextProviderProps

  React.useEffect(() => {
    setLocationData(getLocationData())
  }, [myClosestStore])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <OmniPage
      Head={props.head}
      transport={props.transport}
      imageTransformationProxyUrl={IMAGE_PROXY_URL}
      data={props.data}
      version={props.version}
      locationData={locationData}
      renderHooks={props.renderHooks}
    />
  )
}

export default Page
